<template>
<div>
    <div v-if="record">
        <section class="record-details wrapper-title">
            <div class="record-details-title">
                <h1>{{ record.title }}</h1>
                <h3>By:
                    <router-link v-for="author in record.authors" :key="author.slug"
                            :to="{name: 'author', params: {slug: author.slug}, hash: '#' + format_url(author)}">
                        {{ author.name }}
                    </router-link>
                </h3>
            </div>
            <div class="record-details-identifier">
                <span>Map entry</span>
                <span class="record-identifier">{{ record.identifier }}</span>
            </div>
        </section>
        <hr />
        <section class="wrapper-content">
            <h4>Feature Type</h4>
            {{ record.feature_type }}
        </section>

        <hr />
        <section class="wrapper-content">
            <div class="description" v-html="record.description"></div>
            <div aria-hidden="hidden" style="clear: both;"></div>
        </section>

        <hr v-if="record.sub_voce.length > 0" />

        <section v-if="record.sub_voce.length > 0" class="wrapper-content">
            <h4>Sub Voce</h4>
            <span v-for="(subvoce, index) in record.sub_voce" :key="index">{{ subvoce.name }}<span v-if="index < record.sub_voce.length - 1">, </span></span>
        </section>

        <hr />

        <section v-if="record.citations.length > 0" class="wrapper-content">
            <h4>Citations</h4>
            <ul class="citations text-small">
                <li v-for="(cite, index) in record.citations" :key="index" v-html="cite" />
            </ul>
        </section>
    </div>
</div>
</template>

<script>
import { mapGetters } from "vuex"

import Loading from "@/components/Loading"

export default {
    name: 'Record',
    components: {
        Loading
    },    
    data: function() {
        return {
            seo_title: '',
            seo_desc: ''
        }
    },
    computed: {
        ...mapGetters({
            'loading': 'records/record_loading',
            'record': 'records/record'
        })
    },
    head: {
        title: function() {
            return {
                inner: this.record ? this.record.title : ''
            }
        },
        meta: function () {
            return [       
                {name: 'description', content: this.seo_desc, id: 'seo-desc' },
                {name: 'og:description', content: this.seo_desc, id: 'og-desc' },
                {name: 'og:title', content: this.seo_title, id: 'og-title'}
            ]
        }
    },
    methods: {
        load(slug) {
            this.$store.dispatch('records/fetch', slug)
        },
        format_url(author) {
            return author.name.toLowerCase().replace(/[^a-z0-9\s]/gi,'').replace(/\s/g, '-');
        },

    },
    mounted() {
        this.load(this.$route.params.slug)
    },
    watch: {
        '$route.params.slug': function() {
            this.load(this.$route.params.slug)
        },
        record: function() {
            
            let desc = new DOMParser().parseFromString(this.record.description, 'text/html').querySelector('p').textContent;
            desc = desc.substring(0, 300);
            desc += '...';

            this.seo_title = this.record.title;
            this.seo_desc = desc;

            this.$emit('updateHead')
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars.scss";

h1, h2, h3, h4, h5 {
    margin: 0;
}

h2, h3 {
    margin-bottom: 0.5rem;
}

h4 {
    margin-bottom: 0 !important;
}

.citations {
    list-style: none;
    padding: 0;

    p {
        margin-block-start:0.5rem;
        margin-block-end:0.5rem;
    }
}

.description {
    &::v-deep img {
        max-width: 100%;
    }
}

.record-details {
    display: grid;
    grid-template-columns: 65% 35%;

    .record-details-title {
        grid-column: 1;
        grid-row: 1;
    }
    .record-details-identifier {
        grid-column: 2;
        grid-row: 1;
        text-align: right;

        span {
            padding: 0.25rem 0;
        }

        .record-identifier {
            background: $gradient-button;
            border-radius: 3px;
            box-shadow: $glow-ui-inner;
            display: inline-block;
            font-size: 1.15rem;
            margin-left: 10px;
            padding: 0.25rem 1rem;
        }
    }
}
</style>
